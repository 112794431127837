body {
  margin: 0;
  font-family: 'Cinzel', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,.card-title,.card-header,.card-body,.card-footer {
  font-family: 'Cinzel', serif !important;
}

.card{
  font-family: 'Cinzel', serif !important;
}

.shadow-lg.p-3.mb-5.bg-white.rounded.carousel.slide.carousel-dark{
  padding: 0rem !important;
}

.carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: none !important
}

.shadow-lg.p-3.mb-5.bg-white.rounded.carousel.slide.carousel-dark>.carousel-indicators > button {
  background-color: white !important;
}

#myCarousel{
  margin-top: 33px;
}

.d-block{
  height: 32em;
}

.carousel-caption{
  color: white !important;
}

.navbar-brand{
  color: white !important;
}

.menu{
  color: white !important;
}

.dropdown-toggle{
  color: white !important;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  border-color: white !important;
}

.navbar-toggler:focus{
  border-color: white !important;
}
svg {
  vertical-align: text-top !important;
  display: inline;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  border: none;
}

.dropdown-item{
  color: white !important;
  font-size: 15px !important;
  background-color: #1D2951!important;
  border-color:#1D2951;
}

.dropdown-menu,.show{
  color: white !important;
  font-size: 15px !important;
  background-color: #1D2951!important;
  border-color:#1D2951;
}

.dropdown-item:focus{
  color: #1D2951 !important;
  background-color: white !important;
}

a:link {
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.dropdown{
  color: #1D2951 !important;
}

.service-card{
  border-radius: 4px !important;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
  font-family: "Lato", sans-serif !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.service-card:hover{
   transform: scale(1.05);
   box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.service-card > .card-body{
  padding: 0%;
  padding-bottom: 5%;
}

.service-card > .card-body > .container-fluid{
  padding: 0%;
}

.service-card-img{
  width: 100%;
  height: 85%;
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  border-bottom-right-radius: 25px;
}
.card-title{
  margin-top: 15px !important;
  color: #1D2951 !important;
}
 .card-body{
   color: black;
 }

 .footer{
   background-color:  #1D2951;
   color: white;
 }
 .container-fluid.footer > .row > .col-md-6{
  margin-top: 30px;
  margin-bottom: 30px;
 } 
 .container-fluid.footer > .row > .col-md-6 > .container-fluid > .row {
   padding: 7px;
 }

.profile-img{
  height: 24rem;
  width: 100%;
  padding: 8%;
}

 .profile-details {
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  font-family: 'Cinzel', serif !important;
  background-color: #1D2951;
  color: white !important;
  padding: 10px;
 }

.profile-name{
  color:white;
  font-weight: bolder;
  font-family: 'Cinzel', serif !important;
}
.team-profile{
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
  font-family: "Lato", sans-serif !important;
  margin-top: 30px;
  margin-bottom: 30px;
}
.profile-contact{
  color: white !important;
  background-color: #1D2951;
  font-family: 'Cinzel', serif !important;
}

.profile-heading{
  font-weight: bolder !important;
  color: #1D2951;
  font-family: 'Cinzel', serif !important;
  margin-top: 40px;
}

.profile-description{
  margin-bottom: 60px !important;
  font-family: 'Cinzel', serif !important;
  color: #1D2951 !important;
}

.profile-description > ul >li {
  text-align: left;
  padding: 10px;
  font-family: 'Cinzel', serif !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus
.btn-primary:active:focus
{
  background-color: #1D2951 !important;
  border-color: #1D2951 !important;
}

#uncontrolled-tab-example-tab-shravan{
  color: #1D2951;
  font-weight: bolder;
  font-size: 20px;
}

.nav-tabs{
  border-bottom: 3px solid #1D2951 !important;
}

.nav-tabs>.nav-item>.active{
  background-color: #1D2951 !important;
  color: white !important;
  font-weight: bolder;
  font-size: 20px;
}

#uncontrolled-tab-example-tab-aayush{
  color: #1D2951;
   font-weight: bolder;
   font-size: 20px;
}
#uncontrolled-tab-example-tab-akash{
  color: #1D2951;
   font-weight: bolder;
   font-size: 20px;
}
#uncontrolled-tab-example-tab-ajay{
  color: #1D2951;
   font-weight: bolder;
   font-size: 20px;
}
#uncontrolled-tab-example-tab-shahill{
  color: #1D2951;
   font-weight: bolder;
   font-size: 20px;
}

.services{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  margin-top: 30px;
  margin-bottom: 30px;
}

.services-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  padding: 25px !important;
  color: white;
}

.services-body{
  margin-top: 30px;
  margin-bottom: 30px;
  color: #1D2951 !important;
  font-size: large !important;
  font-weight: 400;
}


.services-body > .container >.row > .col-md-6>ul>li{
  text-align: left;
  padding: 10px;
  color: #1D2951 !important;
}

.contactUs{
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
}

.contactUs-header{
 box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
 background-color:#1D2951 !important;
 color:white;

}

#panel1d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel2d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel3d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel4d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel5d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel6d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}

#panel7d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel8d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
#panel9d-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  color: white;
}
.MuiSvgIcon-root{
  color: white;
}

.MuiPaper-root, .MuiPaper-elevation, .MuiPaper-elevation0, .MuiAccordion-root, .css-1fjvggn-MuiPaper-root-MuiAccordion-root{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px #1D2951 !important;
}

.MuiTypography-root, .MuiTypography-body1, .css-ahj2mt-MuiTypography-root{
  font-family: 'Cinzel', serif !important;
}



.services-subtabs{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  margin-top: 30px;
  margin-bottom: 30px;
}

.services-subtabs-header{
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  background-color: #1D2951 !important;
  padding: 12px !important;
  color: white;
}


.about-card{
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
  font-family: "Lato", sans-serif !important;
  margin-top: 30px;
  margin-bottom: 30px;

}


.about-card-header{
  background-color: #1D2951 !important;
  color: white;
  box-shadow: 0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22);
}